exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fundraisers-js": () => import("./../../../src/pages/fundraisers.js" /* webpackChunkName: "component---src-pages-fundraisers-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-get-inspired-blazing-a-trail-of-success-js": () => import("./../../../src/pages/get-inspired/blazing-a-trail-of-success.js" /* webpackChunkName: "component---src-pages-get-inspired-blazing-a-trail-of-success-js" */),
  "component---src-pages-get-inspired-breaking-the-cycle-js": () => import("./../../../src/pages/get-inspired/breaking-the-cycle.js" /* webpackChunkName: "component---src-pages-get-inspired-breaking-the-cycle-js" */),
  "component---src-pages-get-inspired-giving-wings-to-dreams-js": () => import("./../../../src/pages/get-inspired/giving-wings-to-dreams.js" /* webpackChunkName: "component---src-pages-get-inspired-giving-wings-to-dreams-js" */),
  "component---src-pages-get-inspired-index-js": () => import("./../../../src/pages/get-inspired/index.js" /* webpackChunkName: "component---src-pages-get-inspired-index-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-message-from-the-global-head-js": () => import("./../../../src/pages/message-from-the-global-head.js" /* webpackChunkName: "component---src-pages-message-from-the-global-head-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */)
}

